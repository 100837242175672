export const environment = {
  production: true,
  envName: 'production',
  apiurl: 'https://channel-api.stemwerkz.org/',
  apikey: 'bc77612ecd1a2e7f6ace383b4cee7848',
  stemgameapiurl: 'https://sv-api.stemwerkz.org/',
  secretkey: 'bc776',
  valleyUrl: 'https://sv.bja.learn.stemwerkz.org/',
  dashboardUrl: 'https://dashboard.bja.learn.stemwerkz.org/',
  questsUrl: 'https://lesson.bja.learn.stemwerkz.org/',
  liveClassApiurl: 'https://live-api.stemwerkz.org/',
  nodmaUrl: 'https://bja.learn.stemwerkz.org/',
  membershipUrl: 'https://account.stemwerkz.org/',
  stemUrl: 'https://www.stemwerkz.org',
  valleyPopupVideoUrl: 'https://du4diohu7suph.cloudfront.net/stemvalley-demo/steamValleyTeaser1.mp4',
  openAppJSUrl: 'https://pagewerkz-authoring.s3.amazonaws.com/stemwerkz/open-in-app-js/production/openInApp.js',
  stemwerkzStartUrl: 'https://dashboard.bja.learn.stemwerkz.org/',
  disabledAcademy: false,
  // logo: 'chinese',
  logo: 'english',
  showStartView: false, // true or false
  pauseAcademy: true,
  channelLogoUrl: 'assets/image/STEMWerkzLogo2.png',
  channelCnLogoUrl: 'assets/image/STEMWerkzLogo2-ch.png',
  zoneChannelLogoUrl: 'assets/image/Zone-CHANNEL.png',
  zoneChannelCnLogoUrl: 'assets/image/Zone-CHANNEL-ch.png',
  zoneDashboardLogoUrl: './assets/image/Zone-DASHBOARD.png',
  valleyLogoUrl: './assets/image/STEAMValley.png',
  zoneValleyLogoUrl: 'assets/image/Zone-STEAMVALLEY.png',
  zoneValleyCnLogoUrl: 'assets/image/Zone-STEAMVALLEY-ch.png',
  academyLogoUrl: './assets/image/STEMWerkzAcademy.png',
  zoneAcademyLogoUrl: 'assets/image/Zone_ACADEMY.png',
  zoneAcademyCnLogoUrl: 'assets/image/Zone_ACADEMY-ch.png',
  questLogoUrl: 'assets/image/STEMQuest.png',
  zoneQuestLogoUrl: 'assets/image/Zone-STEMQuest.png',
  zoneQuestCnLogoUrl: 'assets/image/Zone-STEMQuest-ch.png',
  subsecretkey: 'gNnzsMiCfSCEMKztg6tz',
  bypassSub: false, // for bypassing subscription
  portalLogoutUrl: 'https://bja.learn.stemwerkz.org/#/logout'
};
